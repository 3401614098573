 body{
    background-color: blueviolet;
    text-align: center;
   font-family: 'Josefin Sans', sans-serif;
 }
 .App{
   margin-top: 200px;
 }
 .mini{
    max-width: 700px;
 }
 .heading{
    text-align: center;
 }
 input[type="text"], input[type="password"]{
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid ; 
 }
 input[type="text"]:focus, input[type="password"]:focus{
      background: transparent;
      outline: none;
  }

  
  #todos{
    border: none;
    
  }
  button {
    border: none;
    background: transparent;
    outline: none;
  }
  .formClass{
    margin: 50px;
  }

  .button:hover{
    cursor: pointer;
    color: var(--bs-indigo); 
    
  }
  .button {
    
    padding: 5px 40px !important;
  }
  button:hover svg {
    color: var(--bs-indigo); 
  }

  button:focus {
    background-color: rgb(243, 230, 255);
    border-radius: 10px;
    color: var(--bs-indigo); 
  }

  .dialog {
    width: 360px;
    height: 320px;
    top: 10px;
    position: relative;
  }

.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
 